import axios from "axios";
import store from "../../store";
import {
    message
} from "ant-design-vue";
import {
    i18n
} from "@/assets/js/vue-i18n-init";
import {
    tokenKey
} from "@/assets/js/helper";

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 60000,
    headers: {
        // "Content-Type": "application/json",
        // CO: "A",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        // "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        "Accept-Language": i18n.locale || "ru"
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem(tokenKey);
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        } else {
            delete config.headers["Authorization"];
        }

        if (!(config.headers["Accept-Language"] === "")) {
            config.headers["Accept-Language"] = i18n.locale || "ru";
        }

        // console.log("req", config);

        // if(config.headers['CO'] === '') {
        // 	delete config.headers['CO']
        // }

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (res) => Promise.resolve(res),
    (error) => {
      // console.log('error', error)
      // console.log('error.response', error.response)
      let result = {};
        if (
            error &&
            error.response &&
            error.response.data &&
            error.response.status === 400
        ) {
            // console.log(error.response.data);
            Promise.resolve(error.response.data);
            result = {
                code: 400,
                text: "Отправленный вами запрос неверный или поврежден",
                response: error.response
            };
        }
        if (
            error &&
            error.response &&
            error.response.data &&
            error.response.status === 429
        ) {
            // console.log(error.response.data);
            Promise.resolve(error.response.data);
            result = {
                code: 429,
                text: "Вы заблокированы на некоторое время!!!",
                detail: error.response.data && error.response.data.detail
            };
        }
        if (
            error &&
            error.response &&
            error.response.data &&
            error.response.status === 402
        ) {
            // console.log(error.response.data);
            Promise.resolve(error.response.data);
            result = {
                code: 402,
                text: "Старый пароль пользователя неверный!!!",
                detail: error.response.data
            };
        }
        if (
            error &&
            error.response &&
            error.response.data &&
            error.response.status === 413
        ) {
            // console.log(error.response.data);
            Promise.resolve(error.response.data);
            result = {
                code: 402,
                text: "Файл слишком большой!!!"
            };
        }

        if (error.response && error.response.status === 401 ||
            error.response && error.response.status === 403) {
            sessionStorage.removeItem("token");
            delete axiosInstance.defaults.headers.common["Authorization"];
            window.location.href = "/#/login";
            store.commit("setToken", "");

            let text = error.response.status === 401 ?
              "Активная учетная запись с указанными учетными данными не найдена":
              "У вас нет разрешения на выполнение этого действия"

            result = {
                code: error.response.status,
                text: text,
            };
            window.location.href = `${process.env.VUE_APP_PREFIX}/#/login`;
        }

        if (error.response && error.response.status === 404) {
            // console.log(error);
            result = {
                code: 404,
                text: "Не найдено",
            };
        }

        if (
            (error.response && error.response.status === 408) ||
            error.code === "ECONNABORTED"
        ) {
            // console.log(`A timeout happend on url ${ error.response }`);
            result = {
                code: 408,
                text: "Истекло время для ожидания запроса",
            };
        }

        if (error.response && error.response.status >= 500) {
            // alert("Ошибка 500: внутренняя ошибка сервера")
            // errStr = "Ошибка 500: внутренняя ошибка сервера";
            message.error("Внутренняя ошибка сервера");
            result = {
                code: 500,
                text: "Ошибка 500: внутренняя ошибка сервера",
            };
        }

        if (
            result &&
            result.code &&
            // result.code !== 400 &&
            error.response &&
            error.response.config &&
            error.response.config.url.indexOf("check-slug") === -1
        ) {
            message.error(`code=(${result.code}) ` + result.text);
            if (error.response.data && error.response.data.message) {
              // console.log('m', error.response.data.message)
              message.error(`backend message: ${error.response.data.message}`);
            }
        }

        Promise.resolve(result);
        return result;
    }
);

export default axiosInstance;