// import store from "@/store"

export const state = () => ({})

export const mutations = {}

export const actions = {
  async fetchInteractiveServices(_, { params }) {
    const { data } = await this.$api.get(
      "/admin/common/interactive-service/list/",
      {
        params
      }
    )
    return data
  },
  async fetchInteractiveServicesById(_, id) {
    const { data } = await this.$api.get(
      `/admin/common/interactive-service/${id}/update/`
    )
    return data
  }
}
