export const state = () => ({})

export const getters = {}

export const mutations = {}

export const actions = {
  fetch() {
    return this.$api.get("/admin/post/tag/list/").then((res) => {
      if (res && res.data) {
        return res.data
      }
    })
  },

  delete(_, payload) {
    return this.$api
      .delete("/tag/delete/" + payload)
      .then((res) => {
        // console.log(res)
        return res
      })
      .catch((err) => console.log(err))
  },

  fetchTagById(_, payload) {
    return this.$api
      .get("/admin/post/tag/" + payload + "/update/")
      .then((res) => {
        return res && res.data
      })
  }
}
