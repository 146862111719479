import uz from "./messages/uz"
import oz from "./messages/oz"
import ru from "./messages/ru"
import en from "./messages/en"

const messages = {
  uz,
  ru,
  oz,
  en
}

export default messages
