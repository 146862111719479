const oz = {
  Add: "Қўшиш",
  IsStatic: "Статик",
  HasCategory: "Категория",
  Delete: "Ўчириш",
  Edit: "Таҳрирлаш",
  goToSite: "Сайтга ўтиш",
  Link: "Ҳавола",
  Type: "Тури",
  SelectType: "Турни танланг",
  Published: "Чоп этилган",
  NotPublished: "Чоп этилмаган",
  UsersColTitle: "Логин",
  UserTitle: "Фойдаланувчилар",
  FormFullName: "Ф.И.Ш",
  FormFirstName: "Исм",
  FormFirstNamePlace: "Исмингизни киритинг",
  FormLastName: "Фамилия",
  FormLastNamePlace: "Фамилиянгизни киритинг",
  FormMiddleName: "Шариф",
  FormMiddleNamePlace: "Шарифингизни киритинг",
  FormBirthDate: "Туғилган йил",
  FormGender: "Жинси",
  FormMale: "Эркак",
  FormFemale: "Айол",
  FormUsername: "Фойдаланувчи номи",
  FormUsernamePlace: "Фойдаланувчи номини киритинг",
  FormPassword: "Пароль",
  FormPasswordPlace: "Паролингизни киритинг",
  FormPhone: "Телефон рақами",
  FormInnerNum: "Ички рақам",
  FormPhoneInfoService: "Ахборот хизмати",
  FormPhoneHelp: "Ишонч телефони",
  FormPhoneContact: "Мурожаат учун телефон",
  FormPhoneInfoServicePlace: "Ахборот хизмати ракамини киритинг",
  FormchancelleryPlace: "Kанцелярия киритинг",
  FormDepartment: "Отдел контроля киритинг",
  FormProfession: "Профессия",
  FormProfessionPlace: "Введите свою профессию",
  FormRole: "Роль",
  FormBirthPlace: "Тугилган жой",
  FormBirthPlacePlaceholder: "Тугилган жойингизни киритинг",
  FormWorkPlace: "Иш жойи",
  FormWorkPlacePlaceholder: "Иш Жойини киритинг",
  FormNat: "Миллат",
  FormNatPlace: "Миллатингизни киритинг",
  FormEdu: "Таълим",
  FormEduPlace: "Таълимингизни киритинг",
  FormUni: "Ўқиш жойи",
  FormUniPlace: "Оқиш жойини киритинг",
  FormSpec: "Мутахассислик",
  FormSpecPlace: "Мутахассисликни киритинг",
  FormAcademDeg: "Илмий даража",
  FormAcademDegPlace: "Илмий даражани киритинг",
  FormAcademtit: "Илмий унвон",
  FormAcademtitPlace: "Илмий унвонни киритинг",
  FormBodies: "Сайланган давлат органлари",
  FormOkrug: "Округ",
  FormParty: "Партия",
  FormPartyPlace: "Партияни киритинг",
  FormFraction: "Фрактсия",
  FormCommittee: "Қўмита",
  // Menu
  MenuTitle: "Меню номи:",
  MenuTitlePlace: "Меню номини киритинг",
  MenuGroup: "Группа:",
  MenuGroupPlace: "Группани киритинг",
  MenuParentName: "Менюни тангланг:",
  MenuPosition: "Позитсия",
  MenIsBlog: "Блог",
  // Tags
  TagsColTitle: "Номи",
  TagsColNumber: "Рақами",
  // Table
  TableTitle: "Номи",
  TableContent: "Таркиб",
  TableShortContent: "Қисқа таркиб",
  TablePhoto: "Расм",
  TablePhotos: "Расмлар",
  TableDoc: "Ҳужжатлар",
  TableHead: "Сарлавҳа",
  TableDate: "Нашр қилинган сана",
  TableCreatedDate: "Яратилган сана",
  TableLastUpdatedDate: "Янгиланганган сана",
  TableAction: "Ҳаракат",
  Lang: "Тиллар",
  Content: "Таркиб",
  IsActive: "Фаолми ?",
  Active: "Фаол",
  Cancel: "Бекор қилиш",
  Save: "Сақлаш",
  SaveAndExit: "Сақлаш ва чиқиш",
  FileDoc: "Ҳужжатлар файллари",
  FileSelect: "файлни танланг",
  LangContent: "Таркиб тиллари",
  LangTitle: "Сарлавҳа тиллари",
  File: "Файл",
  Created: "Яратилган",
  Create: "Яратмоқ",
  AdressPlac: "Манзилни киритинг",
  Source: "Манба",
  SourcePlac: "Манбани киритинг",
  Deputy: "Депутат",
  Video: "Видео",
  selected: "танланган",
  InputText: "Матнни киритинг",
  Leader: "Раҳбар",
  // Committe
  CommitTitle: "Қўмита номи",
  CommitTitlePlac: "Қўмита номини киритинг",
  CommitEdit: "Қўмитани таҳрирлаш",
  Chairman: "Раис",
  SubChairman: "Раис ўринбосари",
  Status: "Статус",
  Url: "URL манзили",
  EnterTheTitle: "Номини киритинг",
  EnterStatus: "Статусни киритинг",
  EnterUrl: "URL манзилни киритинг",
  ListBroadcast: "Жонли эфирлар руйхати бўш!",
  LawsEmpty: "Конунлар руйхати бўш!",
  TranslateCopyTo: "га таржима килиш",
  EnterTitle: "Сарлавхани киритинг",
  DateOfPublication: "Нашр килинган сана",
  Close: "Ёпиш",
  AreasEmpty: "Худудлар руйхати бўш!",
  AddArea: "Майдон кўшиш",
  Region: "Худуд",
  CountiesEmpty: "Вилоятлар руйхати бўш!",
  AddCommissions: "Комиссиялар кўшинг",
  Loading: "Юкланмокда...",
  UploadDocToServer: "Хужжатларни серверга юклаш",
  Downloads: "Юкламалар",
  Consider: "Ўйлаб кўринг",
  ToAnswer: "Жавоб бериш",
  ViewAnswer: "Жавобни кўриш",
  Adress: "Манзил",
  EnterAdress: "Манзилни киритинг",
  SenderStatus: "Юборувчи холати",
  ToWhom: "Кимга",
  Passport: "Паспорт",
  Chancery: "Девонхона",
  EnterChanceryNumb: "Девонхона ракамини киритинг",
  Faks: "Факс",
  EnterFaks: "Факсни киритинг",
  Email: "Почта манзили",
  EnterEmail: "Почта манзилингизни киритинг",
  Bus: "Автобуслар",
  EnterBuses: "Автобус ракамини киритинг",
  MiniBus: "Маршрутлар",
  EnterMinibuses: "Маршрут ракамини киритинг",
  Metro: "Метро",
  EnterMetro: "Метро бекатини киритинг",
  ReferencePoint: "Мўлжал",
  EnterLandmark: "Мулжални киритинг",
  WorkingTime: "Иш вақти",
  Sum: "Нарх",
  Value: "Қиймат",
  Logo: "Логотип",
  Icon: "Иконка",
  Question: "Савол",
  Questions: "Саволлар",
  QuestionTheme: "Савол мавзуси",
  Answer: "Жавоб",
  Author: "Муаллиф",
  ViewsCount: "Кўришлар сони",
  OnSlider: "Асосийга",
  Comments: "Изоҳлар",
  JobTitle: "Лавозим",
  Department: "Департамент",
  HasExpired: "Муддати тугаган",
  AcceptDate: "Қабул қилинган сана",
  Order: "Тартиб",
  Activity: "Фаолияти"
}

export default oz
