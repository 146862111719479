// import store from "@/store"

export const state = () => ({})

export const mutations = {}

export const actions = {
  async fetchResourcelist(_, { params }) {
    const { data } = await this.$api.get("/admin/common/resource/list/", {
      params
    })
    return data
  },
  async fetchResourceById(_, id) {
    const { data } = await this.$api.get(`/admin/common/resource/${id}/update/`)
    return data
  }
}
