// import store from "@/store"

export const state = () => ({})

export const mutations = {}

export const actions = {
  // async fetchUsefull(_, { params }) {
  //   const { data } = await this.$api.get("/admin/common/usefullink/create/", {
  //     params
  //   })
  //   return data
  // },
  async fetchReportlinelist(_, { params }) {
    const { data } = await this.$api.get("/admin/common/reportline/list/", {
      params
    })
    return data
  },
  async fetchReportlineById(_, id) {
    const { data } = await this.$api.get(
      `/admin/common/reportline/${id}/update/`
    )
    return data
  }
}
