import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: "/",
      component: () =>
        import(/* webpackChunkName: "default" */ "./layouts/default"),

      children: [
        {
          path: "",
          component: () =>
            import(/* webpackChunkName: "index-page" */ "./views")
        },
        // users
        {
          path: "/users",
          name: "users",
          component: () =>
            import(/* webpackChunkName: "users" */ "./views/users"),
          meta: {
            dataUrl: "users",
            title: {
              uz: "Foydalanuvchilar",
              oz: "Фойдаланувчилар",
              ru: "Пользователи",
              en: "Users"
            },
            iconName: "team",
            roles: ["admin"]
          }
        },

        // user create
        {
          path: "/user/create",
          name: "user-create",
          component: () =>
            import(
              /* webpackChunkName: "user-create" */
              "./views/users/detail"
            ),
          meta: {
            parent: "users",
            roles: ["admin"]
          }
        },

        // user detail
        {
          path: "/user/:id",
          name: "user-detail",
          component: () =>
            import(
              /* webpackChunkName: "user-detail" */
              "./views/users/detail"
            ),
          meta: {
            parent: "users",
            roles: ["admin"]
          }
        },

        // posts
        {
          path: "/:id/posts",
          name: "posts",
          component: () => import("./views/posts"),
          meta: {
            roles: ["admin", "moderator"]
          }
        },

        // post create
        {
          path: "/:id/posts/create",
          name: "post-create",
          component: () => import("./views/posts/create"),
          meta: {
            roles: ["admin", "moderator"]
          }
        },

        // post update
        {
          path: "/posts/:id/update",
          name: "post-update",
          component: () => import("./views/posts/_id"),
          meta: {
            roles: ["admin", "moderator"]
          }
        },

        // mass
        {
          path: "/press-center/:id/mass",
          name: "mass",
          component: () => import("./views/press-center/mass"),
          meta: {
            // roles: ["admin", "moderator"]
          }
        },

        // post update
        {
          path: "/press-center/mass/update/:id",
          name: "mass-update",
          component: () => import("./views/press-center/mass/_id"),
          meta: {
            // roles: ["admin", "moderator"]
          }
        },

        // announcements
        {
          path: "/:id/announcements",
          name: "announcements",
          component: () => import("./views/posts"),
          meta: {
            roles: ["admin", "moderator"]
          }
        },

        // events
        {
          path: "/:id/events",
          name: "events",
          component: () => import("./views/posts"),
          meta: {
            roles: ["admin", "moderator"]
          }
        },

        // menu
        {
          path: "/menu",
          name: "menu",
          component: () =>
            import(/* webpackChunkName: "menu-list" */ "./views/menu"),
          meta: {
            dataUrl: "menu",
            title: {
              uz: "Menyu",
              oz: "Меню",
              ru: "Меню",
              en: "Menu"
            },
            iconName: "hdd",
            roles: ["admin", "moderator"]
          }
        },

        // menu detail
        {
          path: "/menu/create",
          name: "menu-create",
          component: () =>
            import(/* webpackChunkName: "menu-create" */ "./views/menu/detail"),
          meta: {
            parent: "menu",
            roles: ["admin", "moderator"]
          }
        },

        // menu detail
        {
          path: "/menu/:id",
          name: "menu-detail",
          component: () =>
            import(/* webpackChunkName: "menu-detail" */ "./views/menu/detail"),
          meta: {
            parent: "menu",
            roles: ["admin", "moderator"]
          }
        },

        // ministry
        {
          path: "/ministry",
          name: "ministry",
          component: () => import("./views/ministry"),
          meta: {
            dataUrl: "ministry",
            title: {
              uz: "Vazirlik",
              oz: "Вазирлик",
              ru: "Министерство",
              en: "Ministry"
            },
            iconName: "layout",
            roles: ["admin", "moderator"]
          }
        },
        // activity
        {
          path: "/activity",
          name: "activity",
          component: () => import("./views/activity"),
          meta: {
            dataUrl: "activity",
            title: {
              uz: "Faoliyat",
              oz: "Фаолият",
              ru: "Деятельность",
              en: "Activity"
            },
            iconName: "layout",
            roles: ["admin", "moderator"]
          }
        },
        // headquarters
        {
          path: "/activity/headquarters/:type",
          name: "headquarters",
          component: () => import("./views/activity/headquarters"),
          meta: {
            parent: "activity",
            roles: ["admin", "moderator"]
          }
        },
        // headquarters - create
        {
          path: "/activity/headquarters/:type/create",
          name: "headquarters-create",
          component: () => import("./views/activity/headquarters/create"),
          meta: {
            parent: "headquarters",
            roles: ["admin", "moderator"]
          }
        },
        // headquarters - update
        {
          path: "/activity/headquarters/:type/update/:id",
          name: "headquarters-update",
          component: () => import("./views/activity/headquarters/_id"),
          meta: {
            parent: "headquarters",
            roles: ["admin", "moderator"]
          }
        },
        // doc
        {
          path: "/activity/:act_id/doc",
          name: "doc",
          component: () => import("./views/activity/doc"),
          meta: {
            parent: "activity",
            roles: ["admin", "moderator"]
          }
        },
        // doc - create
        {
          path: "/activity/:act_id/doc/create",
          name: "doc-create",
          component: () => import("./views/activity/doc/detail"),
          meta: {
            parent: "doc",
            roles: ["admin", "moderator"]
          }
        },
        {
          path: "/activity/:act_id/doc/:id",
          name: "doc-update",
          component: () => import("./views/activity/doc/detail"),
          meta: {
            parent: "doc",
            roles: ["admin", "moderator"]
          }
        },
        {
          path: "/activity/staffs",
          name: "activityList",
          component: () =>
            import(
              /* webpackChunkName: "staffs-list" */
              "./views/activity/activityStaffs"
            ),
          meta: {
            // iconName: "layout",
            roles: ["admin", "moderator"]
          }
        },
        // staffs-create create
        {
          path: "/activity/staffs/create",
          name: "activity-create",
          component: () =>
            import(
              /* webpackChunkName: "staffs-upload" */
              "./views/activity/activityStaffs/create"
            ),
          meta: {
            // roles: ["admin", "moderator"]
          }
        },

        // docTheme
        {
          path: "/activity/:act_id/year/:year/docTheme",
          name: "docTheme",
          component: () => import("./views/activity/docTheme"),
          meta: {
            parent: "activity",
            roles: ["admin", "moderator"]
          }
        },
        // docTheme - create
        {
          path: "/activity/:act_id/year/:year/docTheme/create",
          name: "docTheme-create",
          component: () => import("./views/activity/docTheme/detail"),
          meta: {
            parent: "docTheme",
            roles: ["admin", "moderator"]
          }
        },
        // docTheme - update
        {
          path: "/activity/:act_id/year/:year/docTheme/update/:id",
          name: "docTheme-update",
          component: () => import("./views/activity/docTheme/detail"),
          meta: {
            parent: "docTheme",
            roles: ["admin", "moderator"]
          }
        },

        // attachment
        {
          path: "/activity/:docTheme_id/attachment",
          name: "attachment",
          component: () => import("./views/activity/act-docs"),
          meta: {
            parent: "docTheme",
            roles: ["admin", "moderator"]
          }
        },
        // attachment - create
        {
          path: "/activity/:docTheme_id/attachment/create",
          name: "attachment-create",
          component: () => import("./views/activity/act-docs/detail"),
          meta: {
            parent: "attachment",
            roles: ["admin", "moderator"]
          }
        },
        {
          path: "/activity/:docTheme_id/attachment/:id",
          name: "attachment-update",
          component: () => import("./views/activity/act-docs/detail"),
          meta: {
            parent: "attachment",
            roles: ["admin", "moderator"]
          }
        },

        // doc

        {
          path: "/:type/activity/:act_id/aboutAct",
          name: "aboutAct",
          component: () => import("./views/activity/aboutAct"),
          meta: {
            parent: "activity",
            roles: ["admin", "moderator"]
          }
        },
        // doc - create
        {
          path: "/:type/activity/:act_id/aboutAct/create",
          name: "aboutAct-create",
          component: () => import("./views/activity/aboutAct/detail"),
          meta: {
            parent: "aboutAct",
            roles: ["admin", "moderator"]
          }
        },
        {
          path: "/:type/activity/:act_id/aboutAct/:id/update",
          name: "aboutAct-update",
          component: () => import("./views/activity/aboutAct/detail"),
          meta: {
            parent: "aboutAct",
            roles: ["admin", "moderator"]
          }
        },

        // doc

        {
          path: "/:type/activity/:act_id/aboutAct/:about/docs",
          name: "aboutAct-docs",
          component: () => import("./views/activity/aboutAct/aboutDocs"),
          meta: {
            parent: "aboutAct",
            roles: ["admin", "moderator"]
          }
        },
        // doc - create
        {
          path: "/:type/activity/:act_id/aboutAct/:about/create",
          name: "aboutAct-docs-create",
          component: () => import("./views/activity/aboutAct/aboutDocs/detail"),
          meta: {
            parent: "aboutAct-docs",
            roles: ["admin", "moderator"]
          }
        },
        {
          path: "/:type/activity/:act_id/aboutAct/:about/update/:id",
          name: "aboutAct-docs-update",
          component: () => import("./views/activity/aboutAct/aboutDocs/detail"),
          meta: {
            parent: "aboutAct-docs",
            roles: ["admin", "moderator"]
          }
        },

        // flayers
        {
          path: "/:type/activity/:act_id/flayers",
          name: "flayers",
          component: () => import("./views/activity/flayers"),
          meta: {
            parent: "activity",
            roles: ["admin", "moderator"]
          }
        },
        // flayers - create
        {
          path: "/:type/activity/:act_id/flayers/create",
          name: "flayers-create",
          component: () => import("./views/activity/flayers/detail"),
          meta: {
            parent: "flayers",
            roles: ["admin", "moderator"]
          }
        },
        // flayers - update
        {
          path: "/:type/activity/:act_id/flayers/update/:id",
          name: "flayers-update",
          component: () => import("./views/activity/flayers/detail"),
          meta: {
            parent: "flayers",
            roles: ["admin", "moderator"]
          }
        },

        // staffs update
        {
          path: "/activity/staffs/:id",
          name: "activity-detail",
          component: () =>
            import(
              /* webpackChunkName: "staffs-upload" */
              "./views/activity/activityStaffs/_id"
            ),
          meta: {
            // roles: ["admin", "moderator"]
          }
        },

        // opendata
        {
          path: "/opendata",
          name: "opendata",
          component: () => import("./views/opendata"),
          meta: {
            dataUrl: "opendata",
            title: {
              uz: "Ochiq ma'lumotlar",
              oz: "Очиқ маьлумотлар",
              ru: "Открытые данные",
              en: "Opendata"
            },
            iconName: "layout",
            roles: ["admin", "moderator"]
          }
        },
        // opendata - list
        {
          path: "/opendata/:open_id/list",
          name: "opendata-list",
          component: () => import("./views/opendata/list"),
          meta: {
            dataUrl: "opendata",
            roles: ["admin", "moderator"]
          }
        },
        // opendata - list - create - update
        {
          path: "/opendata/:open_id/list/create/",
          name: "opendata-list-create",
          component: () => import("./views/opendata/list/detail"),
          meta: {
            dataUrl: "opendata",
            roles: ["admin", "moderator"]
          }
        },
        {
          path: "/opendata/:open_id/list/:id/update",
          name: "opendata-list-update",
          component: () => import("./views/opendata/list/detail"),
          meta: {
            dataUrl: "opendata",
            roles: ["admin", "moderator"]
          }
        },

        // opendata - docs
        {
          path: "/opendata/:open_id/:list_id/docs",
          name: "opendata-docs",
          component: () => import("./views/opendata/listDocs"),
          meta: {
            dataUrl: "opendata",
            roles: ["admin", "moderator"]
          }
        },
        // opendata - docs - create - update
        {
          path: "/opendata/:open_id/:list_id/docs/create/",
          name: "opendata-docs-create",
          component: () => import("./views/opendata/listDocs/detail"),
          meta: {
            dataUrl: "opendata",
            roles: ["admin", "moderator"]
          }
        },
        {
          path: "/opendata/:open_id/:list_id/docs/:id/update",
          name: "opendata-docs-update",
          component: () => import("./views/opendata/listDocs/detail"),
          meta: {
            dataUrl: "opendata",
            roles: ["admin", "moderator"]
          }
        },

        // documents
        {
          path: "/documents",
          name: "documents",
          component: () => import("./views/documents"),
          meta: {
            dataUrl: "documents",
            title: {
              uz: "Hujjatlar",
              oz: "Ҳужжатлар",
              ru: "Документы",
              en: "Documents"
            },
            iconName: "layout",
            roles: ["admin", "moderator"]
          }
        },
        // dataegov
        {
          path: "/dataegov",
          name: "dataegov",
          component: () => import("./views/dataegov"),
          meta: {
            dataUrl: "dataegov",
            title: {
              uz: "Dataegov",
              oz: "Dataegov",
              ru: "Dataegov",
              en: "Dataegov"
            },
            iconName: "layout",
            roles: ["admin", "moderator"]
          }
        },
        // dataegov-create
        {
          path: "/dataegov/create",
          name: "dataegov-create",
          component: () => import("./views/dataegov/_detail.vue"),
          meta: {
            parent: "dataegov",
            roles: ["admin", "moderator"]
          }
        },
        // dataegov-update
        {
          path: "/dataegov/update/:id",
          name: "dataegov-update",
          component: () => import("./views/dataegov/_detail.vue"),
          meta: {
            parent: "dataegov",
            roles: ["admin", "moderator"]
          }
        },

        // mhh
        {
          path: "/documents/mhh",
          name: "mhh",
          component: () => import("./views/documents/mhh"),
          meta: {
            dataUrl: "mhh",
            roles: ["admin", "moderator"]
          }
        },
        // mhh create
        {
          path: "/documents/mhh/create",
          name: "mhh-create",
          component: () => import("./views/documents/mhh/detail"),
          meta: {
            parent: "mhh",
            roles: ["admin", "moderator"]
          }
        },
        // mhh detail
        {
          path: "/documents/mhh/:id",
          name: "mhh-detail",
          component: () => import("./views/documents/mhh/detail"),
          meta: {
            parent: "mhh",
            roles: ["admin", "moderator"]
          }
        },

        // agency
        {
          path: "/documents/agency",
          name: "agency",
          component: () => import("./views/documents/agency"),
          meta: {
            dataUrl: "agency",
            roles: ["admin", "moderator"]
          }
        },
        // agency create
        {
          path: "/documents/agency/create",
          name: "agency-create",
          component: () => import("./views/documents/agency/detail"),
          meta: {
            parent: "agency",
            roles: ["admin", "moderator"]
          }
        },
        // agency detail
        {
          path: "/documents/agency/:id",
          name: "agency-detail",
          component: () => import("./views/documents/agency/detail"),
          meta: {
            parent: "agency",
            roles: ["admin", "moderator"]
          }
        },
        // laws
        {
          path: "/documents/laws/:type",
          name: "laws",
          component: () => import("./views/documents/laws"),
          meta: {
            dataUrl: "laws",
            roles: ["admin", "moderator"]
          }
        },
        // laws create
        {
          path: "/documents/laws/:type/create",
          name: "laws-create",
          component: () => import("./views/documents/laws/detail"),
          meta: {
            parent: "laws",
            roles: ["admin", "moderator"]
          }
        },
        // laws detail
        {
          path: "/documents/:type/laws/:id",
          name: "laws-detail",
          component: () => import("./views/documents/laws/detail"),
          meta: {
            parent: "laws",
            roles: ["admin", "moderator"]
          }
        },
        // npa
        {
          path: "/documents/laws/:type/npa/:laws_id",
          name: "npa",
          component: () => import("./views/documents/npa"),
          meta: {
            dataUrl: "npa",
            roles: ["admin", "moderator"]
          }
        },
        // npa create
        {
          path: "/documents/laws/:type/:laws_id/npa/create",
          name: "npa-create",
          component: () => import("./views/documents/npa/detail"),
          meta: {
            parent: "npa",
            roles: ["admin", "moderator"]
          }
        },
        // npa detail
        {
          path: "/documents/laws/:type/:laws_id/npa/:id",
          name: "npa-detail",
          component: () => import("./views/documents/npa/detail"),
          meta: {
            parent: "npa",
            roles: ["admin", "moderator"]
          }
        },
        // Kyh
        {
          path: "/documents/kyh",
          name: "kyh",
          component: () => import("./views/documents/kyh"),
          meta: {
            dataUrl: "kyh",
            roles: ["admin", "moderator"]
          }
        },
        // Kyh create
        {
          path: "/documents/kyh/create",
          name: "kyh-create",
          component: () => import("./views/documents/kyh/detail"),
          meta: {
            parent: "kyh",
            roles: ["admin", "moderator"]
          }
        },
        // Kyh detail
        {
          path: "/documents/kyh/:id",
          name: "kyh-detail",
          component: () => import("./views/documents/kyh/detail"),
          meta: {
            parent: "kyh",
            roles: ["admin", "moderator"]
          }
        },
        // npa
        {
          path: "/documents/npa:kyh_id",
          name: "npa",
          component: () => import("./views/documents/npa"),
          meta: {
            dataUrl: "npa",
            roles: ["admin", "moderator"]
          }
        },
        // npa create
        {
          path: "/documents/npa/:kyh_id/create",
          name: "npa-create",
          component: () => import("./views/documents/npa/detail"),
          meta: {
            parent: "npa",
            roles: ["admin", "moderator"]
          }
        },
        // npa detail
        {
          path: "/documents/npa/:kyh_id/:id",
          name: "npa-detail",
          component: () => import("./views/documents/npa/detail"),
          meta: {
            parent: "npa",
            roles: ["admin", "moderator"]
          }
        },

        // vacancy
        {
          path: "/vacancy",
          name: "vacancy",
          component: () => import("./views/vacancy"),
          meta: {
            dataUrl: "vacancy",
            title: {
              uz: "Vakansiyalar",
              oz: "Vakansiyalar",
              ru: "Вакансии",
              en: "Vacancy"
            },
            iconName: "layout",
            roles: ["admin", "moderator"]
          }
        },

        // vacs
        {
          path: "/vacancy/vacs",
          name: "vacs",
          meta: {
            parent: "vacancy",
            roles: ["admin", "moderator"]
          },
          component: () => import("./views/vacancy/vacs")
        },
        // vacs - create
        {
          path: "/vacancy/vacs/create",
          name: "vacs-create",
          meta: {
            parent: "vacs",
            roles: ["admin", "moderator"]
          },
          component: () => import("./views/vacancy/vacs/detail")
        },
        // vacs - update
        {
          path: "/vacancy/vacs/:id",
          name: "vacs-update",
          meta: {
            parent: "vacs",
            roles: ["admin", "moderator"]
          },
          component: () => import("./views/vacancy/vacs/detail")
        },

        // vacDepart
        {
          path: "/vacancy/depart",
          name: "vacDepart",
          meta: {
            parent: "vacancy",
            roles: ["admin", "moderator"]
          },
          component: () => import("./views/vacancy/vacDepart")
        },
        // vacDepart - create
        {
          path: "/vacancy/depart/create",
          name: "vacDepart-create",
          meta: {
            parent: "vacDepart",
            roles: ["admin", "moderator"]
          },
          component: () => import("./views/vacancy/vacDepart/detail")
        },
        // vacDepart - update
        {
          path: "/vacancy/depart/:id",
          name: "vacDepart-update",
          meta: {
            parent: "vacDepart",
            roles: ["admin", "moderator"]
          },
          component: () => import("./views/vacancy/vacDepart/detail")
        },

        // press-center
        {
          path: "/press-center",
          name: "pressCenter",
          component: () => import("./views/press-center"),
          meta: {
            dataUrl: "press-center",
            title: {
              uz: "Matbuot xizmati",
              oz: "Матбуот xизмати",
              ru: "Пресс-служба",
              en: "Press-service"
            },
            iconName: "notification",
            roles: ["admin", "moderator"]
          }
        },
        // daily-info
        {
          path: "/daily-info",
          name: "daily-info",
          component: () => import("./views/daily-info"),
          meta: {
            dataUrl: "daily-info",
            title: {
              uz: "Kunlik ma'lumotlar",
              oz: "Кунлик маьлумотлар",
              ru: "Ежедневная информация",
              en: "Daily information"
            },
            iconName: "notification",
            roles: ["admin", "moderator"]
          }
        },
        // daily-info create
        {
          path: "/daily-info/daily-info-create",
          name: "daily-info-create",
          component: () => import("./views/daily-info/create"),
          meta: {
            parent: "daily-info",
            roles: ["admin", "moderator"]
          }
        },
        // daily-info id
        {
          path: "/daily-info/daily-update/:id/",
          name: "daily-update",
          component: () => import("./views/daily-info/_id"),
          meta: {
            parent: "daily-info",
            roles: ["admin", "moderator"]
          }
        },
        // staffs
        {
          path: "/ministry/staffs",
          name: "staffs",
          component: () =>
            import(/* webpackChunkName: "staffs-list" */ "./views/staffs"),
          meta: {
            // iconName: "layout",
            roles: ["admin", "moderator"]
          }
        },
        // staffs-create create
        {
          path: "/ministry/staffs/create",
          name: "staffs-create",
          component: () =>
            import(
              /* webpackChunkName: "staffs-upload" */
              "./views/staffs/create"
            ),
          meta: {
            // roles: ["admin", "moderator"]
          }
        },

        // staffs update
        {
          path: "/ministry/staffs/:id",
          name: "staffs-detail",
          component: () =>
            import(
              /* webpackChunkName: "staffs-upload" */
              "./views/staffs/_id"
            ),
          meta: {
            // roles: ["admin", "moderator"]
          }
        },

        {
          path: "/ministry/department",
          name: "department",
          component: () =>
            /* webpackChunkName: "department" */
            import("./views/department"),
          meta: {
            // roles: ["admin", "moderator"],
            // dataUrl: "department",
            iconName: "cluster"
          }
        },
        // department create
        {
          path: "/ministry/department/create",
          name: "department-create",
          component: () =>
            /* webpackChunkName: "department-create" */
            import("./views/department/create"),
          meta: {
            parent: "department"
            // roles: ["admin", "moderator"]
          }
        },
        // department detail
        {
          path: "/ministry/department/:id",
          name: "department-detail",
          component: () =>
            /* webpackChunkName: "department-detail" */
            import("./views/department/_id"),
          meta: {
            parent: "department"
            // roles: ["admin", "moderator"]
          }
        },
        // organization
        {
          path: "/organization",
          name: "organization",
          component: () => import("./views/organization"),
          meta: {
            title: {
              uz: "Boshqa Tashkilotlar",
              oz: "Бошқа ташкилотлар",
              ru: "Другие организации",
              en: "Other Organizations"
            },
            dataUrl: "organization",
            iconName: "deployment-unit"
            // roles: ["admin", "moderator"]
          }
        },

        // organization detail
        {
          path: "/organization/:id",
          name: "organization-detail",
          component: () =>
            /* webpackChunkName: "Digitalization" */
            import("./views/organization/organization-detail"),
          meta: {
            parent: "organization",
            roles: ["admin", "moderator"]
          }
        },

        // organization create
        {
          path: "/organization-create",
          name: "organization-create",
          component: () =>
            /* webpackChunkName: "Digitalization" */
            import("./views/organization/organization-create"),
          meta: {
            parent: "organization",
            roles: ["admin", "moderator"]
          }
        },
        // ministry organizations
        {
          path: "/ministry/organizations",
          name: "ministry-organizations",
          component: () => import("./views/ministry_organization"),
          meta: {
            title: {
              uz: "Vazirlik tassarufidagi tashkilotlar",
              oz: "Вазирлик тассаруфидаги ташкилотлар",
              ru: "Организации при Министерстве",
              en: "Organizations under the Ministry"
            },
            dataUrl: "ministry/organizations",
            iconName: "deployment-unit"
            // roles: ["admin", "moderator"]
          }
        },

        // organization detail
        {
          path: "/ministry/organization/:id",
          name: "ministry-organizations-detail",
          component: () =>
            /* webpackChunkName: "Digitalization" */
            import("./views/ministry_organization/organization-detail"),
          meta: {
            parent: "ministry-organizations",
            roles: ["admin", "moderator"]
          }
        },

        // organization create
        {
          path: "/ministry/organization-create",
          name: "ministry-organizations-create",
          component: () =>
            /* webpackChunkName: "Digitalization" */
            import("./views/ministry_organization/organization-create"),
          meta: {
            parent: "ministry-organizations",
            roles: ["admin", "moderator"]
          }
        },

        // galereya
        {
          path: "/press-center/gallery",
          name: "gallery",
          component: () =>
            /* webpackChunkName: "gallery" */
            import("./views/gallery"),
          meta: {
            roles: ["admin", "moderator"]
          }
        },

        // galereya create
        {
          path: "/press-center/gallery/create/:type",
          name: "gallery-create",
          component: () =>
            /* webpackChunkName: "gallery-type-create" */
            import("./views/gallery/detail"),
          meta: {
            parent: "gallery",
            roles: ["admin", "moderator"]
          }
        },

        // galereya detail
        {
          path: "/press-center/gallery/:id",
          name: "gallery-detail",
          component: () =>
            /* webpackChunkName: "gallery-detail" */
            import("./views/gallery/detail"),
          meta: {
            parent: "gallery",
            roles: ["admin", "moderator"]
          }
        },
        // video
        {
          path: "/press-center/video",
          name: "video",
          component: () =>
            /* webpackChunkName: "gallery" */
            import("./views/gallery/category"),
          meta: {
            roles: ["admin", "moderator"]
          }
        },
        // galereya
        {
          path: "/press-center/video/:cat_id/",
          name: "video-list",
          component: () =>
            /* webpackChunkName: "gallery" */
            import("./views/gallery/category/detail.vue"),
          meta: {
            roles: ["admin", "moderator"]
          }
        },
        // video create
        {
          path: "/press-center/video/:cat_id/create/:type",
          name: "video-create",
          component: () =>
            /* webpackChunkName: "gallery-type-create" */
            import("./views/gallery/detail"),
          meta: {
            parent: "gallery",
            roles: ["admin", "moderator"]
          }
        },
        // video detail
        {
          path: "/press-center/video/:cat_id/:id",
          name: "video-detail",
          component: () =>
            /* webpackChunkName: "gallery-detail" */
            import("./views/gallery/detail"),
          meta: {
            parent: "gallery",
            roles: ["admin", "moderator"]
          }
        },

        // contacts
        {
          path: "/contacts",
          name: "contacts",
          component: () =>
            /* webpackChunkName: "contacts" */
            import("./views/contacts"),
          meta: {
            title: {
              uz: "Bog'lanish",
              oz: "Боғланиш",
              ru: "Контакты",
              en: "Contacts"
            },
            dataUrl: "contacts",
            iconName: "contacts",
            roles: ["admin", "moderator"]
          }
        },
        // appeals
        {
          path: "/appeals",
          name: "appeals",
          component: () => import("./views/appeals"),
          meta: {
            dataUrl: "appeals",
            title: {
              uz: "Murojaatlar",
              oz: "Мурожаатлар",
              ru: "Обращении",
              en: "Appeals"
            },
            iconName: "notification",
            roles: ["admin", "moderator"]
          }
        },
        // spelling-mistake
        {
          path: "/spelling-mistake",
          name: "spelling-mistake",
          component: () =>
            /* webpackChunkName: "fractions" */
            import("./views/spelling-mistake"),
          meta: {
            // title: {
            //   uz: "Imloviy xato",
            //   oz: "Имловий хато",
            //   ru: "Орфографическая ошибка",
            //   en: "Spelling mistake"
            // },
            dataUrl: "spelling-mistake",
            // iconName: "profile",
            roles: ["admin", "moderator"]
          }
        },
        // spelling-mistake
        {
          path: "/spelling-mistake/:id/detail",
          name: "spelling-mistake-detail",
          component: () =>
            /* webpackChunkName: "fractions" */
            import("./views/spelling-mistake/_id"),
          meta: {
            roles: ["admin", "moderator"]
          }
        },

        // virtual-reception
        {
          path: "/virtual-reception",
          name: "virtual-reception",
          component: () => import("./views/virtual-reception"),
          meta: {
            dataUrl: "virtual-reception",
            // title: {
            //   uz: "Virtual qabul",
            //   oz: "Виртуал қабул",
            //   ru: "Виртуальная приемная",
            //   en: "Virtual reception"
            // },
            // iconName: "notification",
            roles: ["admin", "moderator"]
          }
        },

        // appeal

        {
          path: "/virtual-reception/appeal",
          name: "appeal",
          component: () => import("./views/virtual-reception/appeal"),
          meta: {
            parent: "virtual-reception",
            roles: ["admin", "moderator"]
          }
        },

        // appeal create

        {
          path: "/virtual-reception/appeal/create",
          name: "appeal-create",
          component: () => import("./views/virtual-reception/appeal/detail"),
          meta: {
            parent: "appeal",
            roles: ["admin", "moderator"]
          }
        },

        // appeal detail

        {
          path: "/virtual-reception/appeal/:id",
          name: "appeal-detail",
          component: () => import("./views/virtual-reception/appeal/detail"),
          meta: {
            parent: "appeal",
            roles: ["admin", "moderator"]
          }
        },

        // appeal

        {
          path: "/virtual-reception/corruption",
          name: "corruption",
          component: () => import("./views/virtual-reception/corruption"),
          meta: {
            parent: "virtual-reception",
            roles: ["admin", "moderator"]
          }
        },

        // help-topics

        {
          path: "/virtual-reception/help-topics",
          name: "help-topics",
          component: () => import("./views/virtual-reception/help-topics"),
          meta: {
            parent: "virtual-reception",
            roles: ["admin", "moderator"]
          }
        },

        // help-topics create

        {
          path: "/virtual-reception/help-topics/create",
          name: "help-topics-create",
          component: () =>
            import("./views/virtual-reception/help-topics/detail"),
          meta: {
            parent: "help-topics",
            roles: ["admin", "moderator"]
          }
        },

        // help-topics detail

        {
          path: "/virtual-reception/help-topics/:id",
          name: "help-topics-detail",
          component: () =>
            import("./views/virtual-reception/help-topics/detail"),
          meta: {
            parent: "help-topics",
            roles: ["admin", "moderator"]
          }
        },

        // help-questions

        {
          path: "/virtual-reception/help-questions",
          name: "help-questions",
          component: () => import("./views/virtual-reception/help-questions"),
          meta: {
            parent: "virtual-reception",
            roles: ["admin", "moderator"]
          }
        },

        // help-questions create

        {
          path: "/virtual-reception/help-questions/create",
          name: "help-questions-create",
          component: () =>
            import("./views/virtual-reception/help-questions/detail"),
          meta: {
            parent: "help-questions",
            roles: ["admin", "moderator"]
          }
        },

        // help-questions detail

        {
          path: "/virtual-reception/help-questions/:id",
          name: "help-questions-detail",
          component: () =>
            import("./views/virtual-reception/help-questions/detail"),
          meta: {
            parent: "help-questions",
            roles: ["admin", "moderator"]
          }
        },

        // faq theme
        {
          path: "/faq",
          name: "faq",
          component: () => /* webpackChunkName: "faq" */ import("./views/faq"),
          meta: {
            title: {
              uz: "Ko'p so'raladigan savollar",
              oz: "Кўп сўраладиган саволлар",
              ru: "Часто задаваемые вопросы",
              en: "Faq"
            },
            roles: ["admin", "moderator"],
            dataUrl: "faq",
            iconName: "question-circle"
          }
        },
        // faq theme create
        {
          path: "/faq/create",
          name: "faq-ThemeCreate",
          component: () =>
            /* webpackChunkName: "faq" */
            import("./views/faq/detail"),
          meta: {
            parent: "faq",
            roles: ["admin", "moderator"]
          }
        },

        // faq theme detail
        {
          path: "/faq/:id",
          name: "faq-ThemeDetail",
          component: () =>
            /* webpackChunkName: "faq" */
            import("./views/faq/detail"),
          meta: {
            parent: "faq",
            roles: ["admin", "moderator"]
          }
        },
        // faq questions
        {
          path: "/faq/questions/:theme_id",
          name: "faq-ques",
          component: () =>
            /* webpackChunkName: "faq" */
            import("./views/faq/questions"),
          meta: {
            roles: ["admin", "moderator"]
          }
        },
        // faq questions create
        {
          path: "/faq/questions/create/:theme_id",
          name: "faq-quesCreate",
          component: () =>
            /* webpackChunkName: "faq" */
            import("./views/faq/questions/create"),
          meta: {
            parent: "faq",
            roles: ["admin", "moderator"]
          }
        },

        // faq questions detail
        {
          path: "/faq/:theme_id/questions/:id",
          name: "faq-quesDetail",
          component: () =>
            /* webpackChunkName: "faq" */
            import("./views/faq/questions/_id.vue"),
          meta: {
            parent: "faq",
            roles: ["admin", "moderator"]
          }
        },

        // interactive-services list
        {
          path: "/interactive-services",
          name: "interactiveServices",
          component: () =>
            /* webpackChunkName: "interactive-services" */
            import("./views/interactive-services"),
          meta: {
            title: {
              uz: "Interaktiv xizmatlar",
              oz: "Интерактив хизматлар",
              ru: "Интерактивные услуги",
              en: "Interactive services"
            },
            dataUrl: "interactive-services",
            iconName: "interaction",
            roles: ["admin", "moderator"]
          }
        },

        // interactive-services detail
        {
          path: "/interactive-services/:id",
          name: "interactiveServices-detail",
          component: () =>
            /* webpackChunkName: "interactive-services" */
            import("./views/interactive-services/interactive-services-detail"),
          meta: {
            parent: "interactiveServices",
            roles: ["admin", "moderator"]
          }
        },

        // interactive-services create
        {
          path: "/interactive-services-create",
          name: "interactiveServices-create",
          component: () =>
            /* webpackChunkName: "interactive-services" */
            import("./views/interactive-services/interactive-services-create"),
          meta: {
            parent: "interactiveServices",
            roles: ["admin", "moderator"]
          }
        },
        // interactive-info list
        {
          path: "/interactive-info",
          name: "interactive-info",
          component: () =>
            /* webpackChunkName: "interactive-services" */
            import("./views/interactive-info"),
          meta: {
            title: {
              uz: "Interaktiv ma'lumot",
              oz: "Интерактив маълумот",
              ru: "Интерактивные информация",
              en: "Interactive information"
            },
            dataUrl: "interactive-info",
            iconName: "interaction",
            roles: ["admin", "moderator"]
          }
        },

        // interactive-info detail
        {
          path: "/interactive-info/:id",
          name: "interactiveInfo-detail",
          component: () =>
            /* webpackChunkName: "interactive-services" */
            import("./views/interactive-info/interactive-info-detail"),
          meta: {
            parent: "interactiveInfo",
            roles: ["admin", "moderator"]
          }
        },

        // interactive-info create
        {
          path: "/interactive-info-create",
          name: "interactiveInfo-create",
          component: () =>
            /* webpackChunkName: "interactive-services" */
            import("./views/interactive-info/interactive-info-create"),
          meta: {
            parent: "interactiveInfo",
            roles: ["admin", "moderator"]
          }
        },

        // usefull-links list
        {
          path: "/usefull-links",
          name: "usefullLinks",
          component: () =>
            /* webpackChunkName: "usefull-link" */
            import("./views/usefull-links"),
          meta: {
            title: {
              uz: "Foydali havolalar",
              oz: "Фойдали хаволалар",
              ru: "Полезные ссылки",
              en: "Useful links"
            },
            dataUrl: "usefull-links",
            iconName: "link",
            roles: ["admin", "moderator"]
          }
        },

        // usefull-link create
        {
          path: "/usefull-links/create",
          name: "usefullLinks-create",
          component: () =>
            /* webpackChunkName: "usefull-link-create" */
            import("./views/usefull-links/usefull-links-create"),
          meta: {
            parent: "usefullLinks",
            roles: ["admin", "moderator"]
          }
        },

        // usefull-link detail
        {
          path: "/usefull-links/:id",
          name: "usefullLinks-detail",
          component: () =>
            /* webpackChunkName: "usefull-link-detail" */
            import("./views/usefull-links/usefull-links-detail"),
          meta: {
            parent: "usefullLinks",
            roles: ["admin", "moderator"]
          }
        },

        // reportline list
        {
          path: "/reportline",
          name: "reportline",
          component: () =>
            /* webpackChunkName: "reportline" */
            import("./views/reportline"),
          meta: {
            title: {
              uz: "Xisobot liniyasi",
              oz: "Хисобот линияси",
              ru: "Строка отчёта",
              en: "Report line"
            },
            dataUrl: "reportline",
            iconName: "link",
            roles: ["admin", "moderator"]
          }
        },

        // reportline detail
        {
          path: "/reportline/:id",
          name: "reportline-detail",
          component: () =>
            /* webpackChunkName: "reportline-detail" */
            import("./views/reportline/detail"),
          meta: {
            parent: "reportline",
            roles: ["admin", "moderator"]
          }
        },

        // reportline create
        {
          path: "/reportline/:id",
          name: "reportline-create",
          component: () =>
            /* webpackChunkName: "reportline-create" */
            import("./views/reportline/create"),
          meta: {
            parent: "reportline",
            roles: ["admin", "moderator"]
          }
        },

        // reviews list
        {
          path: "/reviews",
          name: "reviews",
          component: () =>
            /* webpackChunkName: "reviews" */
            import("./views/reviews/category"),
          meta: {
            title: {
              uz: "Axborot nashrlari",
              oz: "Ахборот нашрлари",
              ru: "Информационные публикации",
              en: "Information publications"
            },
            dataUrl: "reviews",
            iconName: "link",
            roles: ["admin", "moderator"]
          }
        },

        // reviews detail
        {
          path: "/reviews/:cat_id/category",
          name: "reviews-docs",
          component: () =>
            /* webpackChunkName: "reviews-detail" */
            import("./views/reviews"),
          meta: {
            parent: "reviews",
            roles: ["admin", "moderator"]
          }
        },

        // reviews update
        {
          path: "/reviews/:cat_id/update/:id",
          name: "reviews-update",
          component: () =>
            /* webpackChunkName: "reviews-create" */
            import("./views/reviews/detail"),
          meta: {
            parent: "reviews",
            roles: ["admin", "moderator"]
          }
        },

        // reviews create
        {
          path: "/reviews/:cat_id/create",
          name: "reviews-create",
          component: () =>
            /* webpackChunkName: "reviews-create" */
            import("./views/reviews/create"),
          meta: {
            parent: "reviews",
            roles: ["admin", "moderator"]
          }
        },

        // resources
        {
          path: "/resources",
          name: "resources",
          component: () => import("./views/resources"),
          meta: {
            title: {
              uz: "Moliyaviy nashrlar",
              oz: "Молиявий нашрлар",
              ru: "Финансовые публикации",
              en: "Financial publications"
            },
            dataUrl: "resources",
            iconName: "link",
            roles: ["admin", "moderator"]
          }
        },

        // resource create
        {
          path: "/resource/create",
          name: "resource-create",
          component: () => import("./views/resources/create"),
          meta: {
            parent: "resources",
            roles: ["admin", "moderator"]
          }
        },

        // resource update
        {
          path: "/resource/:id/update",
          name: "resource-update",
          component: () => import("./views/resources/_id"),
          meta: {
            parent: "resources",
            roles: ["admin", "moderator"]
          }
        },

        // region
        {
          path: "/region",
          name: "region",
          component: () =>
            /* webpackChunkName: "fractions" */
            import("./views/region"),
          meta: {
            title: {
              uz: "Regionlar",
              oz: "Регионлар",
              ru: "Регион",
              en: "Region"
            },

            dataUrl: "region",
            iconName: "profile"
            // roles: ["admin", "moderator"]
          }
        },
        // region create
        {
          path: "/region/create",
          name: "region-create",
          component: () =>
            import(
              /* webpackChunkName: "user-create" */
              "./views/region/create.vue"
            ),
          meta: {
            parent: "region"
            // roles: ["admin", "moderator"]
          }
        },
        // region detail
        {
          path: "/region/:id",
          name: "region-detail",
          component: () => import("./views/region/detail.vue"),
          meta: {
            parent: "region"
            // roles: ["admin", "moderator"]
          }
        },
        // subdistrict create
        {
          path: "/subdistrict/create",
          name: "subdistrict-create",
          component: () =>
            import(
              /* webpackChunkName: "user-create" */
              "./views/subdistrict/create.vue"
            ),
          meta: {
            parent: "subdistrict"
            // roles: ["admin", "moderator"]
          }
        },
        // subdistrict detail
        {
          path: "/subdistrict/:id",
          name: "subdistrict-detail",
          component: () => import("./views/subdistrict/detail.vue"),
          meta: {
            parent: "subdistrict"
            // roles: ["admin", "moderator"]
          }
        },
        {
          path: "/main-links",
          name: "main-links",
          component: () =>
            /* webpackChunkName: "reviews-detail" */
            import("./views/main-links"),
          meta: {
            parent: "activity",
            roles: ["admin", "moderator"]
          }
        },
        {
          path: "/main-links/:act_id/post",
          name: "ml-post",
          component: () =>
            /* webpackChunkName: "reviews-detail" */
            import("./views/main-links/post"),
          meta: {
            parent: "main-links",
            roles: ["admin", "moderator"]
          }
        },
        {
          path: "/main-links/:act_id/post/:id",
          name: "ml-post-update",
          component: () =>
            /* webpackChunkName: "reviews-detail" */
            import("./views/main-links/post/_id"),
          meta: {
            parent: "main-links",
            roles: ["admin", "moderator"]
          }
        },
        // filestore
        {
          path: "/filestore",
          name: "filestore",
          component: () => import("./views/image-storage"),
          meta: {
            title: {
              uz: "Fayllar ombori",
              oz: "Файллар омбори",
              ru: "Хранилище файлов",
              en: "Filestore"
            },
            dataUrl: "filestore",
            iconName: "cloud-upload",
            roles: ["admin", "moderator"]
          }
        },
        // comments
        {
          path: "/comments",
          name: "comments",
          component: () => import("./views/comments"),
          meta: {
            title: {
              uz: "Izohlar",
              oz: "Изоҳлар",
              ru: "Комментарии",
              en: "Comments"
            },
            dataUrl: "filestore",
            iconName: "message",
            roles: ["admin", "moderator"]
          }
        },
        // comments create
        {
          path: "/comments/create",
          name: "comments-create",
          component: () => import("./views/comments/create"),
          meta: {
            parent: "comments",
            roles: ["admin", "moderator"]
          }
        },
        // comment update
        {
          path: "/comments/:id",
          name: "comments-update",
          component: () => import("./views/comments/_id"),
          meta: {
            parent: "comments",
            roles: ["admin", "moderator"]
          }
        },

        // public council list
        {
          path: "/press-center/public-council",
          name: "public-council",
          component: () =>
            import(
              /* webpackChunkName: "public-council-list" */
              "./views/public-council"
            ),
          meta: {
            // iconName: "layout",
            roles: ["admin", "moderator"]
          }
        },

        // public council create
        {
          path: "/press-center/public-council/create",
          name: "public-council-create",
          component: () =>
            import(
              /* webpackChunkName: "public-council-create" */
              "./views/public-council/create"
            ),
          meta: {
            // roles: ["admin", "moderator"]
          }
        },

        // public council update
        {
          path: "/press-center/public-council/:id",
          name: "public-council-detail",
          component: () =>
            import(
              /* webpackChunkName: "staffs-upload" */
              "./views/public-council/_id"
            ),
          meta: {
            // roles: ["admin", "moderator"]
          }
        }
      ]
    },
    {
      path: "/login",
      component: () =>
        import(/* webpackChunkName: "login" */ "./layouts/loginWindow")
    },
    {
      // Not Found
      path: "*",
      // redirect: '/',
      component: () =>
        import(/* webpackChunkName: "anypage" */ "./layouts/notFound")
    }
  ]
  // mode: 'history'
})
