export const actions = {
  fetch(_, params) {
    return this.$api
      .get("/admin/common/activity/list/", {
        params
      })
      .then((res) => {
        return res && res.data
      })
  },
  fetchDocsTheme(_, params) {
    return this.$api
      .get("/admin/budget/year-information/list/", {
        params
      })
      .then((res) => {
        return res && res.data
      })
  },
  fetchDocs(_, params) {
    return this.$api
      .get("/admin/budget/year-information-attachment/list/", {
        params
      })
      .then((res) => {
        return res && res.data
      })
  },

  createActivity(_, payload) {
    return this.$api
      .post("/admin/common/activity/create/", payload)
      .then((res) => {
        return res
      })
  },
  deletePost(_, payload) {
    return this.$api
      .delete("/admin/common/activity/" + payload + "/delete/")
      .then((res) => {
        return res
      })
  },
  deletePostTheme(_, payload) {
    return this.$api
      .delete("/admin/budget/year-information/" + payload + "/delete/")
      .then((res) => {
        return res
      })
  },
  deletePostDocs(_, payload) {
    return this.$api
      .delete(
        "/admin/budget/year-information-attachment/" + payload + "/delete/"
      )
      .then((res) => {
        return res
      })
  }
}
