import "@/store"

export const state = () => ({
  list: [],
  ministry_list: []
})
export const mutations = {
  setList(state, payload) {
    state.list = payload || []
  },
  setMinistryList(state, payload) {
    state.ministry_list = payload || []
  },
  createItem(state, payload) {
    state.list = [...state.list, payload]
  },
  updateItem(state, payload) {
    const f = state.list.findIndex((item) => item.id === payload.id)
    if (f !== -1) {
      state.list = [
        ...state.list.slice(0, f),
        payload,
        ...state.list.slice(f + 1)
      ]
    }
  },
  deleteItem(state, payload) {
    const f = state.list.findIndex((item) => item.id === payload)

    if (f !== -1) {
      state.list = [...state.list.slice(0, f), ...state.list.slice(f + 1)]
    }
  }
}

export const getters = {
  list: (state) => state.list || [],
  ministry_list: (state) => state.ministry_list || []
}
export const actions = {
  fetch({ commit }) {
    return this.$api
      .get("admin/structure/organization/list/", {
        params: {
          type: 2
        }
      })
      .then((res) => {
        if (res && res.data) {
          commit("setList", res.data)
          return res.data || []
        }
      })
      .catch((err) => console.log(err))
  },
  fetchMinistry({ commit }) {
    return this.$api
      .get("admin/structure/organization/list/", {
        params: {
          type: 1
        }
      })
      .then((res) => {
        if (res && res.data) {
          commit("setMinistryList", res.data)
          return res.data || []
        }
      })
      .catch((err) => console.log(err))
  },
  detail(_, payload) {
    return this.$api
      .get("admin/structure/organization/" + payload + "/update/")
      .then((res) => {
        if (res && res.data) {
          return res.data || {}
        }
      })
      .catch((err) => console.log(err))
  },
  createItemAction({ commit }, payload) {
    return this.$api
      .post("/organization/create", payload.data)
      .then((res) => {
        commit("createItem", res.data)
        return res
      })
      .catch((err) => {
        console.log(err)
      })
  },

  updateItemAction({ commit }, payload) {
    return this.$api
      .put("/organization/update/" + payload.id, payload.data)
      .then((res) => {
        commit("updateItem", res.data)
        return res
      })
      .catch((err) => {
        console.log(err)
      })
  },
  removeItemAction({ commit }, payload) {
    return this.$api
      .delete("admin/structure/organization/" + payload + "/delete/")
      .then((res) => {
        if (res && res.status >= 200) {
          commit("deleteItem", payload)
          return true
        }
        return false
      })
      .catch((err) => {
        console.log(err)
        return false
      })
  },
  async fetchOrganizationById(_, id) {
    const { data } = await this.$api.get(
      `/admin/structure/organization/${id}/update/`
    )
    return data
  },
  async fetchMinistryOrganizationById(_, id) {
    const { data } = await this.$api.get(
      `/admin/structure/organization/${id}/update/`
    )
    return data
  }
}
