export const state = () => ({})

export const getters = {}

export const mutations = {}

export const actions = {
  async fetch() {
    try {
      let res = await this.$api.get("/admin/eventmediaplan/eventtype/list/")
      if (res && res.data) {
        return res && res.data
      }
    } catch (e) {
      console.log(e)
    }
  },
  async fetchMediaplan(_, params) {
    try {
      let res = await this.$api.get("/admin/eventmediaplan/list/", {
        params
      })
      if (res && res.data) {
        return res && res.data
      }
    } catch (e) {
      console.log(e)
    }
  },
  updateMediplan(_, id) {
    return this.$api
      .get("/admin/eventmediaplan/" + id + "/update/")
      .catch((err) => {
        console.log(err)
        return []
      })
  },
  async detail(_, id) {
    try {
      let res = await this.$api.get(
        "/admin/eventmediaplan/eventtype/" + id + "/update/"
      )
      if (res && res.data) {
        return res && res.data
      }
    } catch (e) {
      console.log(e)
    }
  },

  delete(_, payload) {
    return this.$api
      .delete("/tag/delete/" + payload)
      .then((res) => {
        // console.log(res)
        return res
      })
      .catch((err) => console.log(err))
  },
  deleteMediaplan(_, payload) {
    return this.$api
      .delete("/admin/eventmediaplan/" + payload + "/delete/")
      .then((res) => {
        return res
      })
  }
}
