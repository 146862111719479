export const actions = {
  // async fetchAllRegions() {
  //   const { data } = await this.$api.get("/common/region/list/")
  //   return data
  // },
  // async fetchRegionDistricts(_, id) {
  //   const { data } = await this.$api.get(`/common/region/detail/${id}/`)
  //   return data
  // },
  // async fetchSubDistrict(_, payload) {
  //   console.log(payload)
  //   const { data } = await this.$api.get(
  //     `/common/districts/free-sub-district/list/?districts=${payload}`
  //   )
  //   return data
  // },
  async fetchRegionList() {
    const { data } = await this.$api.get("/admin/common/region/list/")
    return data
  },
  async fetchRegionById(_, id) {
    const { data } = await this.$api.get(`/admin/common/region/${id}/update/`)
    return data
  }
}
