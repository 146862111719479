import store from "@/store"

export const state = () => ({
  list: []
})

export const getters = {
  usersList: (state) => state.list || []
}

export const mutations = {
  createItem(state, payload) {
    state.list = [...state.list, payload]
  },
  updateItem(state, payload) {
    const findIndex = state.list.findIndex((item) => item.id === payload.id)
    if (findIndex !== -1) {
      state.list = [
        ...state.list.slice(0, findIndex),
        payload,
        ...state.list.slice(findIndex + 1)
      ]
    }
  },
  deleteItem(state, payload) {
    const findIndex = state.list.findIndex((item) => item.id === payload.id)
    if (findIndex !== -1) {
      state.list = [
        ...state.list.slice(0, findIndex),
        ...state.list.slice(findIndex + 1)
      ]
    }
  }
}

export const actions = {
  fetchList: () => store.$api.get("/admin/user/list/"),
  detail: (_, payload) => store.$api.get("/admin/user/" + payload + "/update/"),
  createItemAction({ commit }, payload) {
    let clonePaylaod = payload
    delete clonePaylaod.id
    return this.$api
      .post("/admin/user/create/", clonePaylaod)
      .then((res) => {
        // console.log(res);
        commit("createItem", res.data)
        return res.data
      })
      .catch((err) => {
        console.log(err)
        return err
      })
  },
  updateItemAction({ commit }, payload) {
    return this.$api
      .put("/admin/user/" + payload.id + "/update/", payload)
      .then((res) => {
        // console.log(res);
        commit("updateItem", res.data)
        return res.data
      })
      .catch((err) => {
        console.log(err)
        return err
      })
  },
  deleteItemAction({ commit }, payload) {
    this.$api
      .delete("/admin/user/delete/" + payload + "/")
      .then((res) => {
        // console.log(res);
        commit("deleteItem", res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
