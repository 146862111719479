// import store from "@/store"

export const state = () => ({})

export const mutations = {}

export const actions = {
  // async fetchUsefull(_, { params }) {
  //   const { data } = await this.$api.get("/admin/common/usefullink/create/", {
  //     params
  //   })
  //   return data
  // },
  async fetchDocumentlist(_, { params }) {
    const { data } = await this.$api.get("/admin/common/document/list/", {
      params
    })
    return data
  },
  async fetchDocumentById(_, id) {
    const { data } = await this.$api.get(`/admin/common/document/${id}/update/`)
    return data
  }
}
