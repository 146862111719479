// import store from "@/store"

export const state = () => ({})

export const mutations = {}

export const actions = {
  async fetchCommentlist(_, { params }) {
    const { data } = await this.$api.get("/admin/comment/list/", { params })
    return data
  },

  async fetchCommentById(_, id) {
    const { data } = await this.$api.get(`/admin/comment/${id}/update/`)
    return data
  },

  removeComment(_, payload) {
    return this.$api
      .delete("/admin/comment/" + payload + "/delete/")
      .then((res) => {
        // console.log(res);
        if (res.status >= 200 && res.status < 300) {
          return true
        }
      })
      .catch((err) => {
        console.log(err)
        return false
      })
  }
}
