// import store from "@/store"

export const state = () => ({})

export const mutations = {}

export const actions = {
  // async fetchUsefull(_, { params }) {
  //   const { data } = await this.$api.get("/admin/common/usefullink/create/", {
  //     params
  //   })
  //   return data
  // },
  async fetchUsefullinks(_, { params }) {
    const { data } = await this.$api.get("/admin/common/usefullink/list/", {
      params
    })
    return data
  },
  async fetchUsefullinksById(_, id) {
    const { data } = await this.$api.get(
      `/admin/common/usefullink/${id}/update/`
    )
    return data
  }
}
