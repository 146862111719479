export const actions = {
  async getAppeals(_, params) {
    return this.$api
      .get("/admin/appeals/status/list/", {
        params
      })
      .then((res) => {
        if (res && res.data) {
          return res.data || []
        }
      })
      .catch((err) => {
        console.log(err)
        return []
      })
  },
  async postAppeals() {
    const { data } = await this.$api.post("/admin/appeals/status/create/")
    return data
  },
  async getAppealsById(_, id) {
    const { data } = await this.$api.get(`/admin/appeals/status/${id}/update/`)
    return data
  },
  //appeals-statics
  // async fetchAppealsStatics() {
  //   const { data } = await this.$api.post("/admin/appeals/statics/create/")
  //   // console.log(data)
  //   return data
  // },
  async getAppealsStatics(_, id) {
    const { data } = await this.$api.get(`/admin/appeals/statics/${id}/update/`)
    // console.log(data)
    return data
  }
}
