export const actions = {
  async fetchAllRegions() {
    const { data } = await this.$api.get("/common/region/list/")
    return data
  },
  async fetchRegionDistricts(_, id) {
    const { data } = await this.$api.get(`/common/region/detail/${id}/`)
    return data
  },
  async fetchSubDistrict(_, params) {
    const { data } = await this.$api.get(
      `/common/sub-district/list/?limit=${params.limit}&offset=${params.offset}`
    )
    return data
  },
  async fetchSubDistrictById(_, id) {
    const { data } = await this.$api.get(`/common/sub-district/update/${id}/`)
    return data
  },
  async fetchDistrictById(_, id) {
    const { data } = await this.$api.get(`/common/district/update/${id}/`)
    return data
  },
  async fetchSubDistrictList(_, { id, limit, offset }) {
    const { data } = await this.$api.get(
      `/common/district/${id}/sub-district/list/?limit=${limit}&offset=${offset}`
    )
    return data
  }
}
