export const state = () => ({})

export const mutations = {}

export const actions = {
  async fetchContacts() {
    const { data } = await this.$api.patch("/admin/contact/create/")
    // console.log(data)
    return data
  },
  async getContact() {
    const { data } = await this.$api.get("/admin/contact/update/")
    return data
  }
}
