export const state = () => ({
  themeList: [],
  list: []
})

export const getters = {
  themeList: (state) => state.themeList || [],
  list: (state) => state.list || []
  // sortedList: (_, getters) =>
  //   getters.list
  //     .map((item) => ({
  //       ...item,
  //       answer: item.answer && JSON.parse(item.answer),
  //       question: item.question && JSON.parse(item.question)
  //     }))
  //     .sort((a, b) => a.position - b.position) || []
}

export const mutations = {
  setList(state, payload) {
    state.themeList = payload || []
  },
  setQues(state, payload) {
    state.list = payload || []
  },

  deleteTheme(state, payload) {
    const findIndex = state.themeList.findIndex((item) => item.id === payload)
    if (findIndex !== -1) {
      state.themeList = [
        ...state.themeList.slice(0, findIndex),
        ...state.themeList.slice(findIndex + 1)
      ]
    }
  },
  deleteQues(state, payload) {
    const findIndex = state.list.findIndex((item) => item.id === payload)
    if (findIndex !== -1) {
      state.list = [
        ...state.list.slice(0, findIndex),
        ...state.list.slice(findIndex + 1)
      ]
    }
  }
}

export const actions = {
  fetchTheme({ commit }, { params }) {
    return this.$api
      .get("/admin/faq/theme/list/", { params })
      .then((res) => {
        if (res && res.data) {
          commit("setList", res.data)
          return res.data || []
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  fetch({ commit }, params) {
    return this.$api
      .get("/admin/faq/list/", { params })
      .then((res) => {
        if (res && res.data) {
          commit("setQues", res.data)
          return res.data || []
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  detailTheme(_, payload) {
    return this.$api
      .get("/admin/faq/theme/" + payload + "/update/")
      .then((res) => {
        return (res && res.data) || {}
      })
      .catch((err) => {
        console.log(err)
      })
  },
  detailQues(_, payload) {
    return this.$api
      .get("/admin/faq/" + payload + "/update/")
      .then((res) => {
        return (res && res.data) || {}
      })
      .catch((err) => {
        console.log(err)
      })
  },
  createThemeAction(_, payload) {
    return this.$api
      .post("/admin/faq/theme/create/", payload.data)
      .then((res) => {
        // console.log('faq createItemAction res', res.data);
        return res.data || {}
      })
      .catch((err) => {
        console.log(err)
      })
  },
  createQuesAction(_, payload) {
    return this.$api
      .post("/admin/faq/create/", payload.data)
      .then((res) => {
        // console.log('faq createItemAction res', res.data);
        return res.data || {}
      })
      .catch((err) => {
        console.log(err)
      })
  },
  updateThemeAction(_, payload) {
    return this.$api
      .patch("/admin/faq/theme/" + payload.id + "/update/", payload.data)
      .then((res) => {
        // console.log('faq updateItemAction', res);
        return res.data || {}
      })
      .catch((err) => {
        console.log("faq", err)
      })
  },
  updateQuesAction(_, payload) {
    return this.$api
      .patch("/admin/faq/" + payload.id + "/update/", payload.data)
      .then((res) => {
        // console.log('faq updateItemAction', res);
        return res.data || {}
      })
      .catch((err) => {
        console.log("faq", err)
      })
  },
  removeThemeAction({ commit }, payload) {
    return this.$api
      .delete("/admin/faq/theme/" + payload + "/delete/")
      .then((res) => {
        // console.log(res);
        if (res.status >= 200 && res.status < 300) {
          commit("deleteTheme", payload)
          return true
        }
      })
      .catch((err) => {
        console.log(err)
        return false
      })
  },
  removeQuesAction({ commit }, payload) {
    return this.$api
      .delete("/admin/faq/" + payload + "/delete/")
      .then((res) => {
        // console.log(res);
        if (res.status >= 200 && res.status < 300) {
          commit("deleteQues", payload)
          return true
        }
      })
      .catch((err) => {
        console.log(err)
        return false
      })
  }
}
